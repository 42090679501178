<template>
    <div id="mallorder">
        <MallheaderVue :customstyle="{ background: '#FFFFFF' }" :titlename="'消费全返'"></MallheaderVue>
        <div class="share-content">
            <div class="share-sign-main">
                <div class="row share-sign-top">
                    <div class="col-xs-9 col-md-9">
                        <span  class="share-sign-top-text">签到获得积分</span>
                        <span class="share-sign-top-text1">{{ signInfo.value }}CNY(元)</span><br/>
                        <span  class="share-sign-top-text">本周签到获得</span>
                        <span class="share-sign-top-text1">{{ signInfo.total }}CNY(元)</span>
                    </div>
                    <div class="col-xs-3 col-md-3 share-sign-padding-none">
                        <div class="share-sign-button" :class="{'share-sign-button-active':canCheck==0}" @click="toCheck">打卡签到</div>
                    </div>
                </div>
                <div class="row share-sign-content">
                    <div class="share-sign-tab" v-for="(item,index) in signArr" :class="{'share-sign-tab-active':item.active==1}">
                        <div><img  :src="require('@/assets/images/mine/day.png')"  alt="" style="width:90%;"></div>
                        <div v-if="item.active==1"><img :src="require('@/assets/images/mine/right.png')" alt="" style="width:60%;"></div>
                        <div v-else>
                            <template v-if="index==0">周一</template>
                            <template v-if="index==1">周二</template>
                            <template v-if="index==2">周三</template>
                            <template v-if="index==3">周四</template>
                            <template v-if="index==4">周五</template>
                            <template v-if="index==5">周六</template>
                            <template v-if="index==6">周日</template>
                            <!--0{{ index+1 }}-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="applycontent">
            <div style="display: flex;">
                <div :class="tab==2?'apply_tab_active':'apply_tab'" @click="changeTab(2)">推广分享</div>
                <div :class="tab==1?'apply_tab_active':'apply_tab'" @click="changeTab(1)">我的待返</div>
            </div>
            <div class="inputbox marginT16" v-if="tab==1">
                <van-field
                        label-align="right"
                        label-width="60"
                        v-model="order_no"
                        label="订单编号"
                        placeholder="请输入订单编号"
                />
            </div>
            <div class="inputbox marginT16" v-if="tab==1">
                <van-field label-align="right" label-width="60" v-model="cloud_balance" label="订单金额" placeholder="请输入订单金额" />
            </div>
            <div class="headlogo marginT16">
                <div>验证</div>
                <div class="uploadbox">
                    <van-uploader v-model="fileList" :max-count="1" :max-size="5000 * 1024" :after-read="clickUpload">
                    </van-uploader>
                </div>
            </div>
            <div class="headlogo marginT16"  v-if="tab==1" style="font-size: 16px;color: #00ffe3;">
                <div>待返金额：￥{{ cloud_balance||0 }}</div>
                <div>价值折合：ATT {{ parseFloat(cloud_balance*cnyToAtt).toFixed(4) }}</div>
            </div>
            <div class="btnbottom">
                <div class="btn"  @click="showSubmit">Web3.0验证</div>
            </div>
            <div class="headlogo marginT16" @click="toTrance()"  style="color: #00ffe3;font-size: 14px;">
                <div>积分映射ATT：
                    <a href="javascript:;" style="color: #ff7220;font-size: 16px;">立即映射</a>
                    (可用余额￥{{ parseFloat(walletList['POINT'].cloud_balance).toFixed(2)||0 }})</div>
                <div>已获得待返：ATT {{ parseFloat(walletList['POINT'].dai_balance).toFixed(2)||0 }}</div>
            </div>
            <div style="color: #07CB65;font-size: 14px;">
                <div >
                    温馨提示：
                </div>
                <div >
                    1.开始映射可开启2.25倍全返 <br>
                    2.新会员直接体验50CNY全返额度
                </div>
            </div>
            <div class="record">
                <div class="title">Web3.0记录</div>
                <div class="datalist">
                    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                        <van-list
                                v-model="loading"
                                :finished="finished"
                                finished-text="暂无数据"
                                @load="onLoad"
                        >
                            <div class="cellcontent margin32"  v-if="recordList.length>0"   v-for="item in recordList"
                                 :key="item.id">
                                <div
                                        class="cell marginbottom24"
                                >
                                    <div class="top">
                                        <div class="title van-ellipsis" v-if="item.type==1">{{ item.order_no }}</div>
                                        <div class="title van-ellipsis" v-if="item.type==2">推广分享</div>
                                        <div class="status" v-if="item.status==1">通过</div>
                                        <div class="status" v-if="item.status==2">Web3.0验证中</div>
                                        <div class="status" v-if="item.status==3">拒绝</div>
                                    </div>
                                    <div class="money margin16"  v-if="tab==1">
                                        <div class="price">
                                            <div class="num">{{ parseFloat(item.cloud_balance).toFixed(2) }}</div>
                                            <div class="pricetype margin8">金额(CNY)：</div>
                                        </div>
                                        <div class="price" v-if="item.att_balance > 0">
                                            <div class="num">
                                                {{ parseFloat(item.att_balance).toFixed(4) }}
                                            </div>
                                            <div class="pricetype margin8">待返(ATT)</div>
                                        </div>
                                    </div>
                                    <div class="way margin24">
                                        <div>
                                            {{ item.timestamp }}
                                        </div>
                                        <div class="method" @click="viewUrl(item.url)">
                                            凭证
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </van-list>
                    </van-pull-refresh>
                </div>
            </div>
        </div>
        <van-dialog v-model="showpwd" title="消费全返" @cancel="pwdShow" @confirm="clickApply" show-cancel-button>
            <van-field
                    type="password"
                    v-model="password"
                    name="安全密码"
                    label="安全密码"
                    placeholder="请输入安全密码"
            ></van-field>
        </van-dialog>
        <van-dialog
                use-slot
                title="签到"
                v-model="showCheck"
                confirmButtonText="开心收下"
                @confirm="checkShow"
        >
            <div style="text-align: center;">
                <img :src="require('@/assets/images/checkin.png')"  style="width:150px;margin: 30px 0 0;" />
                <div style="margin-bottom: 30px;">
                    恭喜您获得签到 <b style="color: #f00;">¥ {{ signInfo.value }}CNY</b>
                </div>
            </div>

        </van-dialog>
    </div>
</template>

<script>
    import { applyOrder, uploadimg,daiList,getSign,signIn,cnyToAtt,pointTodai } from "@/request/shopapi";
    import {withdrawLog,getWalletInfo} from '@/request/api'
    import MallheaderVue from "../../components/mallheader.vue";
    import { Toast } from "vant";
    import { Dialog } from "vant";
    import $ from 'jquery'
    export default {
        name: "EtWebApplyshop",
        components: {
            MallheaderVue,
        },
        data() {
            return {
                showCheck:false,
                checked: false,
                imgurl: "",
                fileList: [],
                order_no: "",
                cloud_balance: "",
                url: "",
                codemessage: "获取验证码",
                disabled: false,
                depositConfig: {
                    mall_ya_coin: "ATT",
                    mall_ya_num: "10",
                },
                showpwd: false,
                password: "",
                address: "",
                userInfo: {},
                recordList: [

                ],
                currentPage: 1,
                refreshing: false,
                loading: false,
                finished: false,
                signInfo:{},
                signArr:[],
                canCheck:0,
                disable:false,
                tab:2,  //1待返订单 2分享
                cnyToAtt:1,
                walletList:{
                    'ARR':{},
                    'ATT':{},
                    'EPT':{},
                    'USDT':{},
                    'POINT':{},
                },
            };
        },
        mounted() {},
        activated() {
            this.address = sessionStorage.getItem("address");
            // this.address ='0x1b1767a7ad755cda5e26ed3335004c323d1015c5';
            this.userInfo = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {};
            this.recordList = [];
            this.currency = 1;
            cnyToAtt({}).then(res=> {
                console.log(res);
                this.cnyToAtt=res.data;
            })
            this.get_sign();
            this.getWalletList();
        },
        methods: {
            showSubmit(){
                if(this.tab==1){
                    Toast('等待开放');
                }else{
                    this.showpwd = true;
                }
            },
            checkShow(){
                this.showCheck = false;
            },
            toTrance(){
                let _this = this;
                Dialog.confirm({
                    title: "确认",
                    message: "您是否确认立即开始映射？",
                    confirmButtonText: "确认",
                    confirmButtonColor: "#FF7220",
                })
                    .then(() => {
                        pointTodai({
                            address: _this.address
                        }).then((res)=> {
                            if (res.code == 200) {
                                Toast(res.msg);
                                _this.getWalletList();
                            } else {
                                Toast(res.msg);
                            }
                        })
                        // on confirm
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
            getWalletList() {
                let _this = this;
                const params = {
                    address: this.address
                }
                getWalletInfo(params).then((res)=> {
                    $.each(res.data.list,function (i,ele) {
                        _this.walletList[ele.coin_symbol] = ele;
                    })
                    console.log(_this.walletList)
                })
            },
            changeTab(tab){
                this.tab = tab;
                this.currentPage = 1
                this.recordList = []
                // 重新加载数据
                this.getDataList()
            },
            async toCheck(){
                if(this.canCheck>0){
                    Toast('今日已经签到，请明日再来');
                    return false;
                }
                if(this.disable){
                    return false;
                }
                this.disable = true;
                let _this =this
                signIn({
                    'address':_this.address,
                }).then((res) => {
                    let success = res.success;
                    if (success) {
                        this.showCheck = true;
                        this.finished = false;
                        this.currentPage = 1
                        this.recordList = []
                        // 重新加载数据
                        // 将 loading 设置为 true，表示处于加载状态
                        this.loading = true;
                        this.get_sign()
                    } else {
                        Toast(res.msg);
                    }
                });

            },
            async get_sign(){
                let _this =this
                getSign({
                    'address':_this.address,
                }).then(res=> {
                    console.log(res)
                    if(res.code==200){
                        _this.signInfo=res.data;
                        _this.signArr=res.data.list
                        _this.canCheck=res.data.canCheck
                    }
                })
            },
            viewUrl(url){
                window.open(url,'_blank')
            },
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.currentPage = 1
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
            onLoad() {
                if(this.address){
                    this.getDataList()
                    this.currentPage++;
                }else{
                    this.loading = false;
                }
            },
            getDataList() {
                const params = {
                    address:this.address,
                    page:this.currentPage,
                    type: this.tab,
                    limit: '20'
                }
                daiList(params).then(res=> {
                    if(this.refreshing){
                        this.refreshing = false
                        this.recordList = []
                    }
                    this.loading = false;
                    this.recordList = [...this.recordList, ...res.data.list]
                    if(res.data.list.length < 20) {
                        this.finished = true;
                    }
                })
            },
            pwdShow() {
                this.showpwd = false;
            },
            requestApplyStore() {
                const params = {
                    address: this.address,
                    pay_code: this.password,
                    order_no: this.order_no,
                    cloud_balance: this.cloud_balance,
                    type: this.tab,
                    url: this.url,
                };
                applyOrder(params).then((res) => {
                    let success = res.success;
                    if (success) {
                        Toast(res.msg);
                        this.finished = false;
                        this.currentPage = 1
                        this.recordList = []
                        // 重新加载数据
                        // 将 loading 设置为 true，表示处于加载状态
                        this.loading = true;
                        this.getDataList()
                    } else {
                        Toast(res.msg);
                    }
                });
            },

            clickApply() {
                let _this = this;

                if (!this.address) {
                    Toast("请使用币安智能链链接");
                    return;
                }
                if (this.address && this.userInfo.has_paypwd != 1) {
                    Toast("请先完善资料,设置安全密码");
                    setTimeout(function() {
                        _this.$router.push({
                            path: "/home/mine",
                        });
                    });
                    return;
                }
                this.requestApplyStore();
            },
            clickUpload(file) {
                console.log(file);
                file.status = "uploading";
                file.message = "上传中...";
                const fd = new FormData();
                fd.append("file", file.file);
                uploadimg(fd)
                    .then((res) => {
                        this.url = res.data.http_url;
                        file.status = "done";
                    })
                    .catch((err) => {
                        console.log(err);
                        file.status = "failed";
                        file.message = "上传失败";
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .share-content{
        margin: 30px 10px;
        background: #E8F1FA;
        border-radius: 8px;
        padding: 15px;
    }
    .share-sign-main{
        background: #fff;border-radius: 6px;padding:8px;
    }
    .share-sign-top{
        font-size: 28px;
    }
    .share-sign-top-text{
        font-size: 28px;color: #78788A;
    }
    .share-sign-top-text1{
        color: #FF9E01;font-weight: bold;
    }
    .share-sign-padding-none{
        padding: 0;
    }
    .share-sign-button{
        background:#F7F7F7;border-radius:4px;padding:10px 0;color:#BABAC6;text-align: center;margin-top: 10px;
        font-weight: bold;
        font-size: 36px;
    }
    .share-sign-button-active{
        background: linear-gradient(to bottom, #FFBF00, #FF8B00);
        color: #fff;
    }
    .share-sign-content{
        margin:10px 0 5px;display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
    }
    .share-sign-tab{
        float: left;width: 13%; background: #F7F7F7;color: #BABAC6;text-align: center;border-radius: 4px;
        padding: 5px;
    }
    .share-sign-tab-active {
        background: #FFB900;

    }
    .applycontent {
        background: #ffffff;
        min-height: 100vh;
        padding: 0 32px 20px 32px;
        .apply_tab_active{
            background: linear-gradient(to bottom, #FFBF00, #FF8B00);
            color: #fff;
            padding: 10px 20px;
            font-size: 28px;
        }
        .apply_tab{
            padding: 10px 20px;
            border: 1px solid #BABAC6;
            color: #BABAC6;
            font-size: 28px;
        }
        .marginT16 {
            margin-top: 16px;
        }
        .inputbox {
            width: 686px;
            height: 84px;
            border-radius: 16px;
            opacity: 1;
            border: 2px solid rgba(255, 223, 204, 1);
            background: rgba(250, 247, 245, 1);
            .verode {
                font-size: 24px;
                font-weight: 400;
                font-family: "PingFang SC";
                text-align: left;
                color: rgba(255, 114, 32, 1);
            }
            ::v-deep .van-cell {
                background-color: transparent;
                line-height: 20px;
            }
            ::v-deep .van-cell__title {
                font-weight: 700;
                font-family: "PingFang SC";
            }
        }
        .headlogo {
            color: rgba(0, 0, 0, 0.9);
            font-size: 24px;
            font-weight: 700;
            font-family: "PingFang SC";
            line-height: 36px;
            .uploadbox {
                margin-top: 8px;
            }
        }
        .textareabox {
            width: 686px;
            min-height: 156px;
            border-radius: 16px;
            opacity: 1;
            border: 2px solid rgba(255, 223, 204, 1);
            background: rgba(250, 247, 245, 1);
            ::v-deep .van-cell {
                background-color: transparent;
            }
            ::v-deep .van-cell__title {
                font-weight: 700;
                font-family: "PingFang SC";
            }
        }
        .agreement {
            .agreetxt {
                display: flex;
                .tip1 {
                    color: rgba(89, 89, 89, 1);
                    font-size: 28px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    text-align: left;
                    line-height: 44px;
                }
                .tip2 {
                    color: rgba(255, 114, 32, 1);
                    font-size: 28px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                    text-align: left;
                    line-height: 44px;
                }
            }
        }
        .btnbottom {
            margin-top: 100px;
            display: flex;
            justify-content: center;
            .btn {
                width: 524px;
                height: 72px;
                border-radius: 74px;
                opacity: 1;
                background: rgba(255, 114, 32, 1);
                color: rgba(238, 238, 238, 1);
                font-size: 28px;
                font-weight: 700;
                font-family: "PingFang SC";
                text-align: center;
                line-height: 72px;
            }
        }
        .record {
            border-radius: 24px;
            opacity: 1;
            background: #faf7f5;
            border: 0.02667rem solid #ffdfcc;
            /*background: rgba(32, 40, 35, 1);*/
            padding: 18px;
            margin-top: 30px;
            .title {
                opacity: 1;
                color: rgba(0,255,227,1);
                font-size: 32px;
                font-weight: 700;
                font-family: "PingFang SC";
            }
            .datalist {
                .cellcontent {
                    .cell {
                        width: 640px;
                        height: 220px;
                        border-radius: 24px;
                        opacity: 1;
                        .top {
                            display: flex;
                            justify-content: space-between;
                            .title {
                                color: #646566;
                                font-size: 32px;
                                font-weight: 400;
                                font-family: "PingFang SC";
                                text-align: center;
                                line-height: 44px;
                            }
                            .status {
                                width: 150px;
                                height: 44px;
                                border-radius: 16px 0 0 16px;
                                background: rgba(39, 199, 204, 0.2);
                                color: rgba(39, 199, 204, 1);
                                font-size: 24px;
                                font-weight: 400;
                                font-family: "PingFang SC";
                                text-align: center;
                                line-height: 44px;
                            }
                        }
                        .money {
                            display: flex;
                            justify-content: space-between;
                            .price {
                                .num {
                                    opacity: 1;
                                    color: rgba(0, 255, 227, 1);
                                    font-size: 44px;
                                    font-weight: 500;
                                    font-family: "DIN";
                                }
                                .pricetype {
                                    color: rgba(139, 166, 149, 1);
                                    font-size: 24px;
                                    font-weight: 400;
                                    font-family: "PingFang SC";
                                }
                            }
                        }
                        .card {
                            width: 638px;
                            height: 64px;
                            border-radius: 16px;
                            opacity: 1;
                            background: rgba(41, 51, 45, 1);
                            display: flex;
                            justify-content: space-between;
                            padding: 0 16px;
                            .time {
                                opacity: 1;
                                color: rgba(139, 166, 149, 1);
                                font-size: 24px;
                                font-weight: 400;
                                font-family: "PingFang SC";
                                line-height: 64px;
                            }
                            .count {
                                opacity: 1;
                                color: rgba(139, 166, 149, 1);
                                line-height: 64px;
                                font-weight: 400;
                                font-family: "PingFang SC";
                                display: flex;
                                ::v-deep .van-count-down {
                                    color: rgba(139, 166, 149, 1);
                                    font-size: 12px;
                                    line-height: 32px;
                                }
                            }
                        }
                        .way {
                            color: rgba(139, 166, 149, 1);
                            font-size: 28px;
                            font-weight: 400;
                            font-family: "PingFang SC";
                            text-align: left;
                            line-height: 40px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-top:15px;
                            .method {
                                display: flex;
                                align-items: center;
                                background: #ff7220;
                                padding: 5px 15px;
                                border-radius: 8px;
                                color: #fff;
                            }
                            .operation {
                                display: flex;
                                align-items: center;
                                .uploadicon {
                                    width: 32px;
                                    height: 32px;
                                }
                                .operate {
                                    color: rgba(39, 204, 127, 1);
                                    font-size: 28px;
                                    font-weight: 700;
                                    font-family: "PingFang SC";
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
